import React, { useState } from 'react'
import { IoIosGlobe } from 'react-icons/io'
import { FaGithub } from 'react-icons/fa'
import styled from 'styled-components'

function Projects() {
    const [modalImage, setModalImage] = useState(null)

    const openModal = (imageSrc) => {
        setModalImage(imageSrc)
    }

    const closeModal = () => {
        setModalImage(null)
    }

    return (
        <>
            {/* My Projects */}
            <ProjectHeader id="projects-title">My Projects</ProjectHeader>
            <ProjectsMain>
                {/* Project 1 */}
                {/* <Project>
                    <ProjectContent>
                        <ProjectImage
                            onClick={() =>
                                openModal(
                                    process.env.PUBLIC_URL + '/images/19.png'
                                )
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/19-front.png'
                                }
                                alt="rollsbar"
                            />
                            <ImageOverlay>Click to View</ImageOverlay>
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Rollsbar</h2>
                            <p>
                                I designed, developed, and deployed a website
                                for a company selling Whiskybars made out of
                                Rolls-Royce radioator grills.
                            </p>
                            <ProjectTech>
                                Next.js, Typescript, Tailwind
                            </ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://www.rollsbar.se/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoIosGlobe /> Live
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project> */}
                {/* Project 2 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage
                            onClick={() =>
                                openModal(
                                    process.env.PUBLIC_URL + '/images/18.png'
                                )
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/18-front.png'
                                }
                                alt="classicrolls"
                            />
                            <ImageOverlay>Click to View</ImageOverlay>
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Classicrolls</h2>
                            <p>
                                I developed and assisted with deploying a
                                website for a company specializing in
                                Rolls-Royce rentals for weddings and other
                                special occasions.
                            </p>
                            <ProjectTech>
                                React, Node.js, styled-components
                            </ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://www.classicrolls.se"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoIosGlobe /> Live
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 3 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage
                            onClick={() =>
                                openModal(
                                    process.env.PUBLIC_URL + '/images/15.png'
                                )
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/15-front.png'
                                }
                                alt="SwedishMarkers"
                            />
                            <ImageOverlay>Click to View</ImageOverlay>
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>SwedishMarkers</h2>
                            <p>
                                Interactive map where you can find points of
                                interests
                            </p>
                            <ProjectTech>
                                React, react-leaflet, Node.js, PostgreSQL, Supabase
                            </ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://joacimstrandvide.github.io/SwedishMarkers/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoIosGlobe /> Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/SwedishMarkers"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 4 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage
                            onClick={() =>
                                openModal(
                                    process.env.PUBLIC_URL + '/images/9.png'
                                )
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/9-front.png'
                                }
                                alt="CountryApi"
                            />
                            <ImageOverlay>Click to View</ImageOverlay>
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>CountryAPI</h2>
                            <p>
                                Search information about countries and their
                                national holidays with a API
                            </p>
                            <ProjectTech>HTML, CSS, Javascript, API</ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="CountryAPI/index.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoIosGlobe /> Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/CountryAPI"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
            </ProjectsMain>
            {/* Collaboration Projects */}
            <ProjectHeader>Collaboration Projects</ProjectHeader>
            <ProjectsMain>
                {/* Project 1 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage
                            onClick={() =>
                                openModal(
                                    process.env.PUBLIC_URL + '/images/16.png'
                                )
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/16-front.png'
                                }
                                alt="Gomoku Game"
                            />
                            <ImageOverlay>Click to View</ImageOverlay>
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Gomoku</h2>
                            <p>Online Gomoku game </p>
                            <ProjectTech>
                                React, styled-components, express
                            </ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://github.com/vikluund/Grupp5-Gomoku"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                                <a
                                    href="https://github.com/MelSilgon/Grupp5-Gomoku-backend"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 2 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage
                            onClick={() =>
                                openModal(
                                    process.env.PUBLIC_URL + '/images/13.png'
                                )
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/13-front.png'
                                }
                                alt="Chat Room"
                            />
                            <ImageOverlay>Click to View</ImageOverlay>
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Chat Room</h2>
                            <p>Chat forum</p>
                            <ProjectTech>
                                React, styled-components, axios, express,
                                PostgreSQL
                            </ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://github.com/MartinEmson/FullstackG3"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 3 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage
                            onClick={() =>
                                openModal(
                                    process.env.PUBLIC_URL + '/images/12.png'
                                )
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/12-front.png'
                                }
                                alt="Resurant"
                            />
                            <ImageOverlay>Click to View</ImageOverlay>
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Restaurant</h2>
                            <p>Website for a restaurant</p>
                            <ProjectTech>Vue, Vuex, Axios</ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://github.com/AlexanderAvernas/Grupp5-project"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
            </ProjectsMain>

            {/* Modal */}
            {modalImage && (
                <Modal>
                    <ModalOverlay onClick={closeModal} />
                    <CloseButton onClick={closeModal}>&times;</CloseButton>
                    <img src={modalImage} alt="larger" />
                </Modal>
            )}
        </>
    )
}

export default Projects

const ProjectHeader = styled.h2`
    margin-left: 2rem;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
    margin-top: 5rem;
    color: var(--text);

    @media (max-width: 640px) {
        text-align: center;
        margin-left: 0;
    }
`
const ProjectTech = styled.p`
    font-weight: 500;
`
const ProjectsMain = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    grid-auto-flow: row;
    margin: 5rem 0.8rem 5rem 0.8rem;
    @media (max-width: 640px) {
        grid-template-columns: 1fr;
    }
`
const Project = styled.section`
    color: var(--text);
`
const ProjectContent = styled.section`
    display: flex;

    @media (max-width: 640px) {
        display: block;
    }
`
const ProjectImage = styled.section`
    flex: 1;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    &:hover div {
        opacity: 1;
    }
`
const ImageOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsl(0, 0%, 0%);
    border-radius: 2rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
`
const ProjectDetails = styled.section`
    flex: 1;
    padding: 10px;
    font-family: 'Oswald', sans-serif;
    line-height: 1.6;
`
const ProjectLinks = styled.section`
    a {
        margin: 5px 8px 0px 0px;
        padding: 0.5rem;
        color: var(--text);
        font-size: 1.1rem;
        text-decoration: none;
        transition: 0.4s;
        border: 2px solid #000;
        border-radius: 10px;
    }

    a:hover {
        box-shadow: 5px 5px 5px 0px rgba(22, 22, 22, 0.75);
    }
`
const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    img {
        max-width: 90%;
        max-height: 90%;
        border-radius: 8px;
    }
`
const ModalOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
`
const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    z-index: 1001;

    &:hover {
        color: red;
    }
`
